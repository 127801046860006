/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Button, Image, Subtitle } from '@swp/components'
import SiteHeader from '../../components/en/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader />

        <Column className="--style4 --left --full" name={"introduction"} style={{"backgroundColor":"var(--color-blend--95)"}} fullscreen={true}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 el--2 mt--06 flex--center" anim={"2"} animS={"3"} style={{"maxWidth":1310}} columns={"2"}>
            
            <ColumnWrapper className="pb--0 pl--0 pr--0 pt--0" anim={""} animS={"3"} style={{"maxWidth":500}}>
              
              <Title className="title-box fs--72" style={{"maxWidth":""}} content={"Lead a life of balance and mental health"}>
              </Title>

              <Text className="text-box fs--20" style={{"maxWidth":""}} content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, etc. can be customized."}>
              </Text>

              <Button className="btn-box btn-box--shape4" content={"Book a session"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="mt--10 pb--0 pl--0 pr--0 pt--0" anim={""} animS={"3"}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/131/img-1_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"100vw"} style={{"maxWidth":1080}} srcSet={"https://cdn.swbpg.com/t/i/template/131/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/131/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/131/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/131/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/131/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--60 pt--60" name={"services"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 el--3" anim={"2"} animS={"3"} style={{"maxWidth":1450}} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":380}}>
              
              <Title className="title-box" content={"<span style='color: var(--color-dominant);'>Individual therapy</span>"}>
              </Title>

              <Text className="text-box" content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, etc. can be customized."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":380}}>
              
              <Title className="title-box" content={"<span style='color: var(--color-dominant);'>Couple and family therapy</span>"}>
              </Title>

              <Text className="text-box" content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, etc. can be customized."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":380}}>
              
              <Title className="title-box" content={"<span style='color: var(--color-dominant);'>Crisis intervention</span>"}>
              </Title>

              <Text className="text-box" content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, etc. can be customized."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-112a1br --style2 --center bg--top --full" name={"quote"} style={{"backgroundColor":"var(--color-blend--95)"}} fullscreen={true} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/131/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/131/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/131/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/131/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/131/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/131/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/131/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/131/fullscreen_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex js-anim  --anim7 --anim-s3 --center el--1 flex--center" anim={"7"} animS={"3"} style={{"maxWidth":1310}} columns={"1"}>
            
            <ColumnWrapper className="pb--0 pl--0 pr--0 pt--0" anim={""} animS={"3"} style={{"maxWidth":900}}>
              
              <Title className="title-box fs--48 lh--14" style={{"maxWidth":""}} content={"<span style='color: white;'>It does not matter how slowly you go as long as you do not stop.</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--60 pt--60" name={"information"}>
          
          <ColumnWrap className="column__flex --left el--1" style={{"maxWidth":1450}} columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim4 --anim-s3" anim={"4"} animS={"3"} style={{"maxWidth":""}}>
              
              <Title className="title-box fs--48" style={{"maxWidth":""}} content={"How I can help you"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80" name={"information-2"} layout={"l13"}>
          
          <ColumnWrap className="column__flex --left el--3 flex--top" style={{"maxWidth":1450}} columns={"3"}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s3 pb--40 pl--40 pr--40 pt--40" anim={"2"} animS={"3"} style={{"backgroundColor":"var(--color-blend--95)"}}>
              
              <Subtitle className="subtitle-box" content={"You don't feel like yourself"}>
              </Subtitle>

              <Text className="text-box" content={"Click and start writing. This is an area for your text."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2 --anim-s3 pb--40 pl--40 pr--40 pt--40" anim={"2"} animS={"3"} style={{"backgroundColor":"var(--color-blend--95)"}}>
              
              <Subtitle className="subtitle-box" content={"You suffer from anxiety"}>
              </Subtitle>

              <Text className="text-box" content={"Click and start writing. This is an area for your text."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2 --anim-s3 pb--40 pl--40 pr--40 pt--40" anim={"2"} animS={"3"} style={{"backgroundColor":"var(--color-blend--95)"}}>
              
              <Subtitle className="subtitle-box" content={"You're can't make decisions"}>
              </Subtitle>

              <Text className="text-box" content={"Click and start writing. This is an area for your text."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2 --anim-s3 pb--40 pl--40 pr--40 pt--40" anim={"2"} animS={"3"} style={{"backgroundColor":"var(--color-blend--95)"}}>
              
              <Subtitle className="subtitle-box" content={"You have insomnia"}>
              </Subtitle>

              <Text className="text-box" content={"Click and start writing. This is an area for your text."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2 --anim-s3 pb--40 pl--40 pr--40 pt--40" anim={"2"} animS={"3"} style={{"backgroundColor":"var(--color-blend--95)"}}>
              
              <Subtitle className="subtitle-box" content={"You lack confidence"}>
              </Subtitle>

              <Text className="text-box" content={"Click and start writing. This is an area for your text."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2 --anim-s3 pb--40 pl--40 pr--40 pt--40" anim={"2"} animS={"3"} style={{"backgroundColor":"var(--color-blend--95)"}}>
              
              <Subtitle className="subtitle-box" content={"You feel burned out"}>
              </Subtitle>

              <Text className="text-box" content={"Click and start writing. This is an area for your text."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="bg--bottom --parallax pb--80 pt--80" name={"contact"} style={{"backgroundColor":"var(--color-blend--95)"}} parallax={true}>
          
          <ColumnWrap className="column__flex js-anim  --anim7 --anim-s3 --center el--1" anim={"7"} animS={"3"} columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s3" anim={"7"} animS={"3"} style={{"maxWidth":1310}}>
              
              <Title className="title-box" content={"Lead a life of balance and mental health"}>
              </Title>

              <Button className="btn-box btn-box--shape4" content={"Book a session"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"footer"} style={{"backgroundColor":"var(--color-supplementary)"}} layout={"l1"}>
          
          <ColumnWrap className="column__flex --left el--2" style={{"maxWidth":1450}} columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"Psychotherapy & counseling"}>
              </Subtitle>

              <Title className="title-box" content={"<span style='color: var(--color-dominant);'> Alena Novakova</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":375}} content={"Click and start writing. This is an area for your text."}>
              </Text>

              <Text className="text-box" style={{"maxWidth":375}} content={"Created with <a href=\"https://saywebpage.com\">saywebpage.com</a>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"Kontakt"}>
              </Subtitle>

              <Title className="title-box" content={"<span style='color: var(--color-dominant);'>510-851-40X4</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":""}} content={"722 Finch Street<br>info@vase-stranky.com"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}